import React from 'react';

import Layout from '../components/layout'; 
import Head from '../components/head';

const NotFound = () => {
  return (
    <Layout>
      <Head title="404 Error Page" />
      <div className="p-20">
        <h1 className="mb-6 text-3xl font-bold">Page Not Found</h1>
        <p>I'm sorry, it looks like this page doesn't exist. Please <a
          href="https://github.com/jmkaneshiro/gatsby-bootcamp"
          target="_blank"
          rel="noreferrer"
          className="text-teal-500 hover:text-teal-700 hover:font-bold">
          open an issue on Github
          </a> or <a
              href="mailto:jmkaneshiro@gmail.com?Subject=Bug%20Report%20:%20Details%20Here"
              target="_blank"
              rel="noreferrer"
              className="text-teal-500 hover:text-teal-700 hover:font-bold">
              contact me
          </a> if there is something that needs to be fixed.
        </p>
      </div>
    </Layout>
  );
};

export default NotFound;